<script setup lang="ts">
import Button from '~/components/design-system/button.vue';
import { useSiteStore } from '~/stores/site.store';
import { LockIcon, GearIcon } from '../../design-system/icons/index';
import { ChevronRightIcon } from '@heroicons/vue/24/outline';
import { useAuthStore } from '~/stores/auth.store';
import ExternalIcon from '~/components/design-system/icons/ExternalIcon.vue';
const site = useSiteStore();
const auth = useAuthStore();

const { $t } = useNuxtApp();

function liveChatClicked() {
  site.toggleFeature({ feature: 'displayLiveChat' });
}

function goToRG() {
  site.deactivateModal();
  auth.setAccountModalPage('responsible-gaming');
  auth.toggleAccountModal(true);
}

function logout() {
  site.deactivateModal();
  auth.logout({ reload: true, redirect: true });
}

function continueSession() {
  //TODO: reset session limit when API ready
  site.deactivateModal();
}
</script>

<template>
  <div>
    <div class="mx-2">
      <div class="flex flex-col justify-center items-center flex-wrap mt-3">
        <div class="flex justify-center">
          <div class="lock-circle p-2 flex">
            <LockIcon class="w-10 h-10 text-gold-500" />
          </div>
        </div>
        <div class="flex flex-col">
          <p
            class="my-3 text-center"
            v-html="$t('session-limit-exceeded-body')"
          />
          <p
            class="text-center mb-4 line-height-3 px-2"
            v-if="auth?.getRestrictionPermissions?.allowedWithdrawal"
            v-html="$t('only-permitted-access-withdrawal-session-limit')"
          />
        </div>
      </div>
      <!-- <div class="separator my-2" /> -->
      <!-- Support Divs -->
      <!-- Live chat -->
      <div
        class="flex justify-between bg-light-200 dark:bg-dark-800 rounded-md mb-2 pl-3 cursor-pointer py-1"
        @click="liveChatClicked()"
      >
        <div class="flex items-center mt-0.5 mb-0.5">
          <ExternalIcon class="w-6 mt-1 mr-3" icon-name="chat-support" />
          <p class="my-0 font-bold mr-1 line-height-1">{{ $t('chat') }}</p>
          <p class="my-0 line-height-1">{{ $t('to-live-support') }}</p>
        </div>
        <ChevronRightIcon
          class="w-4 dark:text-light-50 text-dark-700 mr-2 stroke-2 stroke-current"
        />
      </div>
      <!-- Responsible gaming page -->
      <div
        class="flex justify-between bg-light-200 dark:bg-dark-800 rounded-md mb-4 pl-3 cursor-pointer py-1"
        @click="goToRG()"
      >
        <div class="flex items-center mt-0.5 mb-0.5">
          <GearIcon class="!w-6 !h-6 mt-1 mr-3 dark:text-white text-dark-700" />
          <p class="my-0 mr-1 line-height-1" v-html="$t('view-rg-page')" />
        </div>
        <ChevronRightIcon
          class="w-4 dark:text-light-50 text-dark-700 mr-2 stroke-2 stroke-current"
        />
      </div>
    </div>
    <div class="bg-base dark:bg-layer-1 w-full px-2 py-1 flex gap-2">
      <Button
        class="w-full my-2 justify-center"
        type="primary"
        @click="logout()"
      >
        {{ $t('log-out') }}
      </Button>
      <Button
        class="w-full my-2 justify-center"
        type="secondary"
        @click="continueSession()"
      >
        {{ $t('continue-session') }}
      </Button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
p {
  color: #2b303b;
  .dark & {
    color: #fff;
  }
}

.lock-circle {
  border: 6px solid #ffc018;
  border-radius: 100%;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #dae0ed;
  .dark & {
    background-color: #464f60;
  }
}
</style>
